/* Include the core Angular Material styles */
@import './custom-palettes';

@import '../src/assets/scss/common.scss';
@import '../src/assets/scss/animation.scss';
@import '../src/assets/scss/signup.scss';

@import '~@angular/material/prebuilt-themes/indigo-pink.css';
//@import '../src/assets/scss/breadcrumb.scss';
@import "../node_modules/swiper/swiper-bundle.min.css";
@import 'assets/css/fontawesome-all.min.css';
@import'../src/assets/scss/ck-editor.scss';

@font-face {
    font-family: "gotam-narrow";
    src: url("../src/assets/fonts/gotham-narrow/GothamPro-Medium.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "gotam-narrow-bold";
    src: url("../src/assets/fonts/gotham-narrow/GothamPro-Bold.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "gotam-narrow-light";
    src: url("../src/assets/fonts/gotham-narrow/GothamPro.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "Naskh Regular";
    src: url("../src/assets/fonts/KFGQPCUthmanTahaNaskhRegular.woff2") format("woff2");
    font-display: swap;
}

@font-face {
    font-family: "martel-bold";
    src: url("../src/assets/fonts/Martel-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "martel";
    src: url("../src/assets/fonts/Martel-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "mulish";
    src: url("../src/assets/fonts/Mulish-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "mulish-bold";
    src: url("../src/assets/fonts/Mulish-Bold.ttf") format("truetype");
}

@font-face {
    font-family: "mulish-medium";
    src: url("../src/assets/fonts/Mulish-Medium.ttf") format("truetype");
}

@font-face {
    font-family: "mulish-semibold";
    src: url("../src/assets/fonts/Mulish-SemiBold.ttf") format("truetype");
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth !important;
}

body {
    font-family: $gotamnarrow;
    font-size: 16px;
    color: $body-text;
}

strong {
    font-family: $gotamnarrowbold;
}

.row-flex {
    display: flex;
    flex-wrap: wrap;
}

.card {
    display: block;
}

span.small {
    font-size: 12px;
}

.link-see-all {
    display: block;
    margin-top: 12px;
    font-size: 12px;
}

.fw-bold {
    font-family: $gotamnarrowbold !important;
    font-weight: normal !important;
}

.fw-normal {
    font-family: $gotamnarrowlight !important;
}

.display-1,
.display-2,
.display-3,
.display-4,
.display-5,
.display-6 {
    font-family: $gotamnarrowlight !important;
}

.text-light {
    font-family: $gotamnarrowlight !important;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
    padding-right: var(--bs-gutter-x, 0.938rem);
    padding-left: var(--bs-gutter-x, 0.938rem);
    max-width: calc(1235px + var(--bs-gutter-x, 0.938rem));
}

.row {
    --bs-gutter-x: 1.875rem;
}

a {
    text-decoration: none;
}

.fc-button-group button {
    background-color: #e3a810 !important;
    border-color: #e3a810 !important;

    span {
        color: #000 !important;
    }
}

.fc-event {
    border-color: #e3a810 !important;
}

.calendar-event {
    background-color: #e3a810 !important;
    border-color: #e3a810 !important;
}

.btn-calendar.active {
    background-color: #e3a810 !important;
}

.mat-calendar-body-cell {
    padding-bottom: 15px !important;
}

.mat-input-element::placeholder {
    color: #313131;
}

input.mat-input-element {
    margin-top: 2px;
}

@-moz-document url-prefix() {
    html {
        scroll-behavior: auto !important;
    }
}

.jejakimani-header {
    position: relative;
}

.jejakimani-header.text-black {
    .main-menu ul li a {
        color: $body-text;
    }
}

.jejakimani-page-item {
    margin: 0 4px;

    .page-link {
        width: 40px;
        height: 40px;
        color: #000;
        border: none;
        border-radius: 8px;
        background-color: #fff;
        box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.05), 0 1px 18px 0 rgba(0, 0, 0, 0.05), 0 3px 5px 0 rgba(0, 0, 0, 0.05);

        span {
            text-align: center;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            line-height: 40px;
        }
    }
}

.jejakimani-page-item.active {

    .page-link {
        color: #fff;
        background-color: #e3a810 !important;
        border-color: #e3a810 !important;
    }
}


.jejakimani-carousel {
    margin-top: 100px;
    height: 100vh;
}

.jejakimani-carousel .carousel-item img {
    height: 100vh;
    object-fit: cover;
}

// .jejakimani-carousel .carousel-item:after {
//     content: "";
//     display: block;
//     position: absolute;
//     top: 0;
//     bottom: 0;
//     left: 0;
//     right: 0;
//     background: rgba(0, 0, 0, 0.6);
// }

.jejakimani-carousel .carousel-caption {
    position: absolute;
    right: 15%;
    bottom: 0;
    top: 0;
    left: 15%;
    height: fit-content;
    margin: auto;
    z-index: 9;
}

.jejakimani-carousel .carousel-caption-left {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    height: fit-content;
    margin: auto;
    z-index: 9;
}

.btn-menu-mobile {
    position: absolute;
    bottom: 0;
    top: 0;
    right: 24px;
    margin: auto;
    height: fit-content;
}

.navbar-brand img {
    height: 64px;
}

.jejakimani-nav {
    background-color: transparent;
    z-index: 999;
}

.jejakimani-nav.sticky {
    background-color: #000;
    z-index: 999;
}

.jejakimani-nav .nav-item a.nav-link {
    color: #fff;
    font-weight: bold;
    padding-right: 1.4rem;
    padding-left: 1.4rem;
}

.rounded-lg {
    border-radius: 14px !important;
}

.rounded-4 {
    border-radius: 14px !important;
}

.btn-jejakimani {
    position: relative;
    z-index: 9;
    background-color: #efb10f;
    border-color: #efb10f;
    color: #000 !important;
}

.btn-jejakimani:hover {
    background-color: #e3a810;
    border-color: #e3a810;
}

.btn-jejakimani:focus {
    background-color: #e3a810 !important;
    border-color: #e3a810 !important;
    color: #000 !important;
}

.btn-jejakimani:disabled,
button.btn-jejakimani[disabled]{
  border-color: #999999;
  background-color: #cccccc;
  color: #666666 !important;
}

.wrapper-btn-editor-add {
    position: absolute;
    top: 12px;
    right: 56px;
    z-index: 100;
    text-align: right;
}

.wrapper-btn-editor {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 100;
    text-align: right;
}

.btn-editor {
    margin-right: 6px;
    background-color: #efb10f;
    border-color: #efb10f;
    color: #000 !important;
    border-radius: 64px;
    width: 36px;
    height: 36px;
    padding: 0;
}

.btn-editor:hover {
    background-color: #e3a810;
    border-color: #e3a810;
}

.btn-editor:focus {
    background-color: #e3a810 !important;
    border-color: #e3a810 !important;
    color: #000 !important;
}

.btn-editor-logout {
    position: absolute;
    top: 12px;
    left: 12px;
    z-index: 9999;
    background-color: #efb10f;
    border-color: #efb10f;
    color: #000 !important;
    border-radius: 64px;
    width: 36px;
    height: 36px;
    padding: 0;
}

.swiper-grid>.swiper-wrapper {
    flex-direction: column;
}

.btn-editor-logout:hover {
    background-color: #e3a810;
    border-color: #e3a810;
}

.btn-editor-logout:focus {
    background-color: #e3a810 !important;
    border-color: #e3a810 !important;
    color: #000 !important;
}

.text-jejakimani {
    color: #efb10f !important;
}
.text-ji {
    color: #CA9A04 !important;
}

.jejakimani-section {
    margin: 3rem 0;
}

.jejakimani-section-new {
    padding: 3rem 0;
}

.jejakimani-section-new.lg {
    padding: 6rem 0;
}

.jejakimani-section-caption {
    text-align: center;
}

.jejakimani-section-caption img {
    height: 24px;
}

.jejakimani-card img {
    height: 240px;
    object-fit: cover;
}

.img-heigh-cover {
    height: 40vh;
    object-fit: cover;
}

.middle-content {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    padding: 2em;
    height: fit-content;
    z-index: 99;
}

.card-img-overlay:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
}

.jejakimani-color {
    color: #e3a810 !important;
}

.tourPackageSwiper {
    width: 100%;
}

.jejakimani-card-package {
    position: relative;
    width: 100%;
    height: 380px;
}

.package-image {
    border-radius: 5px;
    overflow: hidden;

    img {
        // transition: all 1.5s cubic-bezier(0, 0, 0.2, 0);
        width: 100%;
        height: 378px;
        object-fit: cover;
    }
}

.package-image.overlay:after {
    content: "";
    display: block;
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, .6);
}

.jejakimani-card-package:hover .package-image img {
    transform: scale3d(1.1, 1.1, 1.1);
}

.overlay {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.4);
}

.overlay-hero {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.6);
}

.jejakimani-card-package .caption {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 1em;
    right: 1em;
    margin: auto;
    text-align: center;
    height: 62px;
    border: 1px solid #fff;
    border-radius: 64px;
    color: #fff;
    font-size: 2.4em;
    line-height: 1.6em;
    z-index: 9;
}

.jejakimani-card-package .caption-small {
    position: absolute;
    bottom: 0;
    top: 0;
    left: 1em;
    right: 1em;
    margin: auto;
    text-align: center;
    height: fit-content;
    color: #fff;
    font-size: 1.4em;
    z-index: 9;
}

.jejakimani-card-package .button {
    position: absolute;
    bottom: -16px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
}

.jejakimani-home-article {
    position: relative;
}

.jejakimani-home-article .content-wrapper {
    position: relative;
    text-align: center;
    padding: 0 24px 42px;
}

.jejakimani-home-article .content-wrapper .divider {
    background-color: #efb10f;
    height: 3px;
    width: 25%;
    margin: 30px auto 14px;
}

.jejakimani-home-article .button {
    position: absolute;
    bottom: -16px;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    z-index: 9;
}

.jejakimani-home-article.bg-black .content-wrapper {
    color: #fff;
}

.bg-black {
    background-color: #000;
}

.bg-jejakimani {
    background-color: #efb10f;
}

.bg-gray {
    background-color: #eee;
}

.bottom-form-contact {
    width: 100%;
    height: 200px;
    position: absolute;
    bottom: 0;
}

.jejakimani-footer {
    position: relative;
    background-color: #000;
    padding: 5em 0;
    color: #fff;
}

.jejakimani-footer p {
    color: #fff !important;
    font-size: 16px;
}

.jejakimani-footer h2 {
    margin-bottom: 1.4em;
    padding-bottom: 7px;
    position: relative;
    border-bottom: 6px solid #fff;
    font-weight: bold;
    font-size: 26px;
    color: #fff;
}

.tour-package-box {
    min-height: 380px;
}

.jejakimani-footer h2:before {
    position: absolute;
    background: #efb10f;
    height: 6px;
    content: '';
    width: 50px;
    bottom: -6px;
    left: 0;
}

.jejakimani-footer .copyright {
    position: absolute;
    width: 100%;
    padding: 1em 2em;
    text-align: center;
    bottom: 0;
    color: #000;
    text-transform: uppercase;
    font-weight: bold;
    background: url('assets/img/jejakimani/bg-copyright.webp') repeat-y center;
}

.footer-icon a {
    margin-bottom: 12px;
    margin-right: 12px;
}

.badge-blue {
    background-color: #0381fe;
    color: #fff;
    padding: 0 4px;
}

.badge-red {
    background-color: #ff0706;
    color: #fff;
    padding: 0 4px;
}

.product-img {
    position: relative;
    min-height: 300px;
    height: 100%;
}

.product-img .content {
    position: absolute;
    bottom: 0;
    text-align: left;
    padding: 20px;
    color: #fff;
}

.product-img .content .ribbon {
    position: absolute;
    color: #fff;
    left: 0;
    top: -70px;
    padding: 0;

    img {
        width: 100%;
        height: 60px;
        object-fit: scale-down;
        object-position: left;
    }
}

.product-img .airline-ribbon {
    position: absolute;
    color: #fff;
    right: 0;
    top: 22px;
    padding: 4px 10px 4px 16px;
    background-color: #fff;
    border-radius: 20px 0 0 20px;
}
.product-img .airline-ribbon img {
    height: 26px;
    width: auto;
    object-fit: scale-down;
    object-position: right;
}

.product-img .content-middle {
    position: absolute;
    width: 100%;
    height: fit-content;
    bottom: 0;
    top: 0;
    margin: auto;
    text-align: center;
    padding: 20px;
    color: #fff;
}

.product-img img {
    width: 100%;
    height: 300px;
    object-fit: cover;
    object-position: top;
}

.jejakimani-form-contact .form-label {
    font-size: 1.2em;
    color: #3e3e3e;
}

.jejakimani-form-contact .form-contact:focus {
    outline: none;
}

.jejakimani-form-contact .form-contact {
    display: block;
    width: 100%;
    border: 0;
    border-bottom: 1px solid #ccc;
    padding: 0.25rem 0 !important;
    font-family: 'gotam-narrow-light' !important;
}
.jejakimani-form-contact h3 {
    font-size: 20px;
}

.jejakimani-testimonial {
    position: relative;
    padding: 5em 0 1em;
    background: url('assets/img/jejakimani/bg-testimony.png') repeat-y left center;
}

.testimonial-content {
    position: relative;

    .quote-top {
        position: absolute;
        top: -30px;
        left: 20px;

        img {
            width: 48px !important;
            height: 48px !important;
        }
    }

    .quote-bottom {
        position: absolute;
        bottom: -30px;
        right: 20px;

        img {
            width: 48px !important;
            height: 48px !important;
        }
    }
}

.card.testimonial {
    background-color: #fff;
    border-top-left-radius: 36px;
    border-top-right-radius: 36px;
    border-bottom-left-radius: 36px;
    border-bottom-right-radius: 36px;
}

.testimonial-customer-photo {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 100%;
}

.bg-clone1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    background-color: #efb10f;
    height: 380px;
    z-index: 9;
}

.vertical-divider {
    position: absolute;
    width: 1px;
    background-color: #000;
    height: 200px;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
}

.shadow-left {
    //box-shadow: inset 4px 14px 14px #eee
    border-left: 1px solid #dfdfdf !important;
}

.bg-whatsapp {
    position: absolute;
    font-size: 12rem;
    line-height: 17rem;
    top: 0;
    bottom: 0;
    left: 18%;
    margin: auto;
    color: #fff;
}

.bg-sapphire {
    background: url('assets/img/jejakimani/bg-sapphire.webp') no-repeat center;
    background-size: cover;
}

.bg-package-emerald {
    background-color: #3ecc22 !important;
}

.bg-package-ruby {
    background-color: #ff0706 !important;
}

.bg-package-sapphire {
    background-color: #2b75d0 !important;
}

.bg-package-plus {
    background-color: #d0752b !important;
}

.carousel-indicators li {
    position: relative;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: 12px;
    height: 12px;
    border-radius: 64px;
    margin-right: 3px;
    margin-left: 3px;
    text-indent: -999px;
    background-color: #ffd464;
}

.carousel-indicators li.active {
    background-color: #f0b10e;
}

.jejakimani-icbanner {
    height: 200px;
    width: 200px;
}

.img-arrow-accord {
    height: 35px;
    width: 300px;
}

.img-arrow-accord-sm {
    height: 25px;
    width: 127px;
}

.swiper {
    width: 100%;
}

.grid-all-package {
    .swiper-container {
        width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;
    }

    .swiper-slide {
        text-align: center;
        height: calc((100% - 30px) / 2) !important;
        width: 100%;
        /* Center slide text vertically */
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .product-wrapper h5 {
        color: #fff;
    }
}

.swiper-wrapper.grid-all-package {
    // flex-direction: unset !important;
    height: 900px;
}

.header-slider {
    .swiper-pagination-bullet-active {
        background: #fff !important;
    }
    .swiper-pagination {
        text-align: left;
        padding-left: 24px;
    }
}

.elipsis-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    height: 90px;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.img-fluid {
    width: 100%;
}

ul.list-bullets li {
    list-style: disc !important;
}

ul.list-number li {
    list-style-type: decimal !important;
}

ul.list-number li {
    ul {
        padding-left: 24px;
    }
}

.loading-article {
    min-height: 800px;
}

.jejakimani-card-article {
    position: relative;
    width: 100%;

    p {
        font-size: 14px;
        margin-bottom: 0;
        color: #000;
    }

    p.small {
        font-size: 14px;
        color: #777;
        margin-bottom: 0;
    }

    h3 {
        font-size: 20px;
    }
}

.article-image {
    border-radius: 5px;
    overflow: hidden;

    img {
        // transition: all 1.5s cubic-bezier(0, 0, 0.2, 0);
        height: 280px;
        object-fit: cover;
        width: 100%;
    }
}

.jejakimani-card-article:hover .article-image img {
    transform: scale3d(1.1, 1.1, 1.1);
}

.ql-align-center {
    text-align: center;
}

.jejakimani-card-jios {
    position: relative;
    width: 100%;

    p {
        font-size: 16px;
        margin-bottom: 0;
    }

    p.small {
        font-size: 14px;
        color: #555;
        margin-bottom: 0;
    }
}

.jios-image {
    border-radius: 5px;
    overflow: hidden;

    img {
        // transition: all 1.5s cubic-bezier(0, 0, 0.2, 0);
        height: 100px;
        object-fit: cover;
        width: 100%;
    }
}

.category-button-prev {
    width: 30px;
    height: 30px;
    border: 1px solid $border;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    background: $white;
    position: absolute;
    top: 46%;
    transform: translate(30%, -50%);
    z-index: 2;
    left: 0;
    transition: 0.3s;
    cursor: pointer;

    @media #{$xs} {
        transform: translate(25%, -50%);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        line-height: 30px;
    }

    &:hover {
        border-color: $theme-color;
        background: $theme-color;
        color: $white;
    }
}

.line {
    width: 100%;
    background-color: rgb(80, 80, 80, 0.3);
    height: 2px;
    position: absolute;
    bottom: 0;
    transform: translate(0px, -15px);
}

.category-button-next {
    width: 30px;
    height: 30px;
    border: 1px solid $border;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    background: $white;
    position: absolute;
    top: 46%;
    transform: translate(-30%, -50%);
    z-index: 2;
    right: 0;
    transition: 0.3s;
    cursor: pointer;

    @media #{$xs} {
        transform: translate(-25%, -50%);
        width: 30px;
        height: 30px;
        border-radius: 50%;
        line-height: 30px;
    }

    &:hover {
        border-color: $theme-color;
        background: $theme-color;
        color: $white;
    }
}

.h-100px {
    height: 100px;
}

.category-button-nomargin-prev {
    width: 45px;
    height: 45px;
    border: 1px solid $border;
    text-align: center;
    line-height: 45px;
    border-radius: 50%;
    background: $white;
    position: absolute;
    top: 46%;
    // transform: translate(-50%, -50%);
    z-index: 2;
    left: 0;
    transition: 0.3s;
    cursor: pointer;

    @media #{$xs} {
        transform: translate(-25%, -50%);
    }

    &:hover {
        border-color: $theme-color;
        background: $theme-color;
        color: $white;
    }
}

.category-button-nomargin-next {
    width: 45px;
    height: 45px;
    border: 1px solid $border;
    text-align: center;
    line-height: 45px;
    border-radius: 50%;
    background: $white;
    position: absolute;
    top: 46%;
    // transform: translate(50%, -50%);
    z-index: 2;
    right: 0;
    transition: 0.3s;
    cursor: pointer;

    @media #{$xs} {
        transform: translate(25%, -50%);
    }

    &:hover {
        border-color: $theme-color;
        background: $theme-color;
        color: $white;
    }
}

.product-wrapper span.title {
    height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}

.img-background-package {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.partner-img {
    height: 100px;
    object-fit: contain;
}

.section-header {
    h2 {
        font-family: "martel-bold", sans-serif;
        font-size: 36px;
        font-weight: 800;
        line-height: 44px;
        letter-spacing: -0.02em;
        color: #101828;
        margin-bottom: 20px;
    }

    p {
        font-family: 'Mulish', sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 30px;
        color: #475467;
        margin: 0;
        padding: 0;
    }

    a.section-header-link {
        font-family: 'mulish', sans-serif;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0%;
        color: #BE9234;
        text-align: right;
        display: inline-block;
        width: 100%;
    }
}

/* EDITOR */
.form-editor {
    .form-control {
        height: unset;
        font-family: "gotam-narrow-light" !important;
        margin-bottom: 1em;
    }

    .btn-file {
        position: absolute;
        opacity: 0;
        right: 0;
        top: 0;
    }

    .btn-delete-file {
        position: absolute;
        top: 12px;
        right: 12px;
    }

    img {
        height: 300px;
        width: 100%;
        object-fit: contain;
        background-color: #ccc;
    }
}

.btn-delete-file-circle {
    border-radius: 64px;
    width: 36px;
    height: 36px;
}

.floating-btn-seo-tag {
    position: fixed;
    bottom: 12px;
    right: 12px;
    height: 50px;
    z-index: 999999999;
}

.btn-seo-tag {
    margin-right: 6px;
    background-color: #0fccef;
    border-color: #0fccef;
    color: #fff !important;
    border-radius: 64px;
    width: 136px;
    height: 48px;
    padding: 0;
}

.btn-seo-tag:hover {
    background-color: #0fccef;
    border-color: #0fccef;
}

.btn-seo-tag:focus {
    background-color: #0fccef !important;
    border-color: #0fccef !important;
    color: #fff !important;
}

.display-p {
    font-size: 24px;
}

.article-author {
    font-size: 14px;
    font-family: $gotamnarrowbold;
}

.article-wrapper, .ustadz-wrapper {
	font-family: $gotamnarrowlight;
    p {
        font-size: 16px;
        color: #000;
        margin-bottom: 4px;
        text-align: justify;
    }
    img {
        max-width: 100%;
        height: auto;
    }
}
.ribbon-draft {
    position: fixed;
    z-index: 9999;
    background: #efb10f;
    box-shadow: 0 0 0 999px #efb10f;
    clip-path: inset(0 -100%);
    inset: 0 0 auto auto;
    transform-origin: 0 0;
    transform: translate(29.3%) rotate(45deg);
    font-size: 1.2em;
    padding: 10px;
    color: #fff;
}

.product-star {
    position: absolute;
    left: 7px;
    top: -13px;
    z-index: 9;

    span {
        color: #e0a60e !important;
    }

    span.fa-star {
        margin-right: 2px;
    }
}

.alert-copy {
    left: 50px;
    font-size: initial;
    bottom: -40px;
    padding: 10px 30px;
    box-shadow: 0px 0px 20px 1px #dddddd;
    border-radius: 2px;
}

.alert-copy.show {
    opacity: 100;
}

.alert-copy.hidden {
    opacity: 0;
    transition: opacity 0.3s ease-in;
    transition: opacity 0.3s ease-out;
}

.before-footer-wrapper {
    height: auto;
    overflow: hidden;
    padding-bottom: 100px;
    font-size: 14px !important;
}

.before-footer-wrapper.hidden {
    max-height: 400px;
    padding-bottom: 0;
}

.before-footer-body>p {
    font-size: 16px;
    margin-bottom: 16px;
}

.dropdown-before-footer {
    position: absolute;
    height: 22px;
    width: 100%;
    bottom: 0;
    right: 0;
    background-color: white;
    z-index: 0;
    box-shadow: 0px 0px 40px 52px white;
}

.tox.tox-silver-sink.tox-tinymce-aux .tox-notifications-container {
    opacity: 0 !important;
}

.tox-tinymce-aux {
    z-index: 999999 !important;
}

.alert {
    font-size: 12px;
}

.border-black {
    border-color: #000 !important
}

.ck-content {
    ul {
        margin: unset;
        padding-left: 2rem;
    }
}
.ngx-lite-video {
    border-radius: 10px;
}
.runner-text {
    margin: 24px 0;
    background-color: #efb10f;
    padding: 18px 0;
    color: #000;
    font-size: 24px;
}
.loading-page {
    width: 100%;
    height: 100vh;
    background-color: #fff;
    position: fixed;
    z-index: 999999;
}
.loading-spinner {
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #0000000a;
    border-radius: 36px;
    height: 180px;
    width: 180px;
    text-align: center;
    line-height: 200px;
}
.spinner-grow {
    width: 3rem; 
    height: 3rem;
}
.box-product-swiper {
    min-height: 336px;
}
.img-thumbnail-youtube {
    width: 100%;
    height: 280px;
    object-fit: cover;
    object-position: center;
    border-radius: 14px;
    cursor: pointer;
}
.form-check-label {
    font-family: 'gotam-narrow-light' !important;
}
@media only screen and (min-width: 1060px) {

    .program-image {
        img {
            width: 75%;
            height: 100%;
        }
    }

    .accomodation-image {
        img {
            width: 75%;
            height: 100%;
        }
    }

    .btn-calendar {
        min-height: 48px;
        width: auto;
        font-size: 20px;
        padding: 0 20px;
        vertical-align: middle;
    }

    .text-departure-date {
        width: 100%;
        margin-right: 10px;
        font-size: 12px;
    }
}

@media only screen and (max-width: 1300px) {

    .program-image {
        img {
            width: 75%;
            height: 100%;
        }
    }

    .accomodation-image {
        img {
            width: 75%;
            height: 100%;
        }
    }
}


@media only screen and (min-width: 768px) {
    .alert {
        font-size: 14px;
    }

    .btn-calendar {
        min-height: 48px;
        width: auto;
        font-size: 12px !important;
        padding: 0 5px !important;
    }

    .text-departure-date {
        width: 100%;
        margin-right: 10px;
        font-size: 12px;
    }

    .alert-copy {
        font-size: 14px;
    }

    .jios-image {
        img {
            height: 180px;
            object-fit: cover;
            width: 100%;
        }
    }

    .jejakimani-section.vision-text-box {
        min-height: 200px;
    }

    .img-heigh-cover {
        height: 100vh;
    }

    .link-see-all {
        display: inline;
        margin-left: 24px;
        font-size: 16px;
    }
}

@media only screen and (max-width:576px) {
    .d-xs-block {
        display: block !important;
    }

    .d-xs-none {
        display: none !important;
    }
}

@media only screen and (max-width: 500px) {
    .text-sm-left {
        text-align: left !important;
    }

    .btn-calendar {
        min-height: 48px;
        width: auto;
        font-size: 10px !important;
        padding: 0 5px !important;
        vertical-align: middle;
    }

    .text-departure-date {
        width: 100%;
        margin-right: 10px;
        font-size: 12px;
    }
    .form-check-label {
        font-size: 12px;
    }

}

@media only screen and (max-width: 768px) {
    .main {
        position: relative;
        padding: 0;
    }

    .letter-bg {
        display: none;
    }

    table td {
        font-size: 12px;
        color: #000;
    }

    li {
        font-size: 12px;
        color: #000;
    }

    .jejakimani-page-item {
        margin: 0 4px;

        .page-link {
            width: 30px !important;
            height: 30px !important;
            color: #000;
            border: none;
            font-size: inherit;
            border-radius: 8px;
            background-color: #fff;
            box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.05), 0 1px 18px 0 rgba(0, 0, 0, 0.05), 0 3px 5px 0 rgba(0, 0, 0, 0.05);

            span {
                text-align: center;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                line-height: 30px;
            }
        }
    }


    .text-sm-left {
        text-align: left !important;
    }

    .form-group {
        label, .form-text {
            font-size: 12px !important;
            line-height: 16px !important;
        }
    }

    .btn-calendar {
        min-height: 48px;
        width: auto;
        font-size: 10px !important;
        padding: 0 5px !important;
        vertical-align: middle;
    }

    .text-departure-date {
        width: 100%;
        margin-right: 10px;
    }

    .program-image {
        width: 100% !important;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .accomodation-image {
        width: 100% !important;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .alert-copy {
        font-size: 12px !important;
        line-height: 16px !important;
    }

    .form-control,
    .form-select {
        font-size: 12px !important;
        line-height: 16px !important;
        padding: 0.75rem !important;
        font-family: 'gotam-narrow-light' !important;
    }

    .jejakimani-form-contact .form-contact {
        font-size: 12px !important;
        line-height: 16px !important;
        font-family: 'gotam-narrow-light';
        padding: 0 0 0.25rem !important;
    }

    .navbar-brand img {
        height: 36px;
    }

    .jejakimani-section.vision-text-box {
        min-height: 200px;
    }

    .tour-package-box {
        min-height: 150px;
    }

    .jejakimani-carousel {
        margin-top: 80px;
        height: 50vh;
    }

    .jejakimani-carousel .carousel-item img {
        height: 50vh;
    }

    .jejakimani-carousel .carousel-caption {
        left: 4%;
        right: 4%;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 14px;
    }

    h4 {
        font-size: 13px;
    }

    h5 {
        font-size: 12px;
    }

    p {
        font-size: 16px !important;
        line-height: inherit;
    }

    p.display-p {
        font-size: 1em !important;
    }

    .jejakimani-card img {
        height: 120px;
    }

    .jejakimani-card-package {
        height: 120px;
    }

    .jejakimani-card-package img.cover {
        height: 120px;
    }

    .display-2 {
        font-size: 1.4em;
    }

    .display-3 {
        font-size: 1.3em;
    }

    .display-4 {
        font-size: 1.2em;
    }

    .display-5 {
        font-size: 1.1em;
    }

    .display-6 {
        font-size: 1em;
    }

    .display-6.mobile {
        font-size: 0.6em !important;
    }

    .card-body {
        padding: .8em;
    }

    .middle-content {
        padding: 6px;
    }

    .btn {
        font-size: 16px;
    }

    .btn-jejakimani {
        font-size: 12px;

        h2 {
            font-size: 16px;
        }
    }

    a.btn-jejakimani {
        font-size: 12px;
    }

    .jejakimani-card-package .caption, .jejakimani-card-package .caption-small {
        left: 0.4em;
        right: 0.4em;
        font-size: 0.8em;
        height: 20px;
    }

    .jejakimani-card-package .caption-small {
        height: fit-content;
    }

    .jejakimani-icbanner {
        height: 80px;
        width: 80px;
    }

    .product-img {
        min-height: 140px;
    }

    .product-img img {
        height: 160px;
    }
    .product-img img.img-other {
        height: 200px !important;
    }

    .product-img .content {
        padding: 5px;
    }

    .product-img .content .ribbon {
        padding: 0px;
        top: -45px;
    }

    .product-img .content .ribbon img {
        height: 32px;
        object-fit: scale-down;
        object-position: left;
    }

    .product-img .airline-ribbon {
        padding: 0px;
        top: 12px;
        padding: 0 4px;
    }

    .product-img .airline-ribbon img {
        height: 10px;
        width: auto;
        object-fit: scale-down;
        object-position: right;
    }
    
    .product-wrapper h5 {
        font-size: 10px;
    }

    .bg-whatsapp {
        font-size: 3rem;
        line-height: 6rem;
    }

    .jejakimani-form-contact .form-label {
        font-size: 12px;
        line-height: 16px;
    }
    .jejakimani-form-contact h3 {
        font-size: 14px;
    }

    .card.testimonial {
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        img {
            height: 80px;
        }

        .card-body {
            padding: 1em;
        }
    }

    .testimonial-content {
        .quote-top {
            position: absolute;
            top: -15px;
            left: 10px;

            img {
                width: 24px !important;
                height: 24px !important;
            }
        }

        .quote-bottom {
            position: absolute;
            bottom: -15px;
            right: 10px;

            img {
                width: 24px !important;
                height: 24px !important;
            }
        }
    }

    .jejakimani-section-new {
        padding: 3rem 0;
    }
    
    .jejakimani-section-new.lg {
        padding: 4rem 0;
    }

    .jejakimani-section-caption img {
        height: 24px;
    }

    .jejakimani-home-article .content-wrapper {
        padding: 0 10px 14px !important;
    }

    .jejakimani-home-article .content-wrapper .divider {
        margin: 10px auto 10px !important;
    }

    .mt-100 {
        margin-top: 20px !important;
    }

    .img-arrow-accord {
        height: 25px;
        width: 127px;
    }

    .swiper-wrapper.grid-all-package {
        flex-direction: unset !important;
        height: 450px;
    }

    .grid-all-package {
        .swiper-container {
            width: 100%;
            height: auto;
            margin-left: auto;
            margin-right: auto;
        }

        .swiper-slide {
            text-align: center;
            height: 200px !important;
            width: 100%;
            /* Center slide text vertically */
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .product-img .content-middle {
        padding: 10px;
    }

    .article-image {
        img {
            // transition: all 1.5s cubic-bezier(0, 0, 0.2, 0);
            height: 180px;
            object-fit: cover;
            width: 100%;
        }
    }

    .jejakimani-card-article {
        p {
            font-size: 12px !important;
            color: #000;
        }

        p.small {
            font-size: 10px !important;
            color: #777;
        }

        h3 {
            font-size: 14px;
            font-family: 'gotam-narrow' !important;
        }
    }

    .mt-150 {
        margin-top: 100px !important;
    }

    .jejakimani-footer h2 {
        font-size: 18px;
    }

    ul.list-number li {
        ul {
            padding-left: 12px;
        }
    }

    .product-wrapper span.title {
        font-size: 0.8em;
        line-height: 16px;
        // height: 62px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .jejakimani-footer .copyright {
        font-size: 0.6em;
    }

    .product-star {
        top: -14px !important;
        font-size: 10px !important;

        span.fa-star {
            margin-right: 2px;
        }
    }

    .ck-content ul {
        padding-left: 1rem;
        font-size: 12px;
    }
    
    .box-product-swiper {
        min-height: 186px;
    }

    .section-header {
        a.section-header-link {
            text-align: left;
            padding: 12px 0;
        }
    }
}



@media only screen and (max-width:768px) {
    .program-image {
        width: 100% !important;

        img {
            width: 75%;
            height: 75%;
        }
    }

    .accomodation-image {
        width: 100% !important;

        img {
            width: 75%;
            height: 75%;
        }
    }
    .img-thumbnail-youtube {
        height: 170px;
    }
    .ustadz-wrapper {
        p {
            font-size: 14px !important;
        }
        li {
            font-size: 14px !important;;
        }
    }
    .header-slider {
        .swiper-pagination-bullet {
            width: 18px !important;
            border-radius: 4px !important;
        }
        .swiper-pagination-bullet-active {
            width: 36px !important;
            background: #fff !important;
        }
        .swiper-pagination {
            text-align: center !important;
            padding: 0 !important;
        }
    }
}

@media only screen and (max-width:550px) {
    .program-image {
        width: 100% !important;

        img {
            width: 100%;
        }
    }

    .accomodation-image {
        width: 100% !important;

        img {
            width: 100%;
        }
    }

    .row.vision-text-box {
        min-height: 180px !important;
    }

    .tour-package-box {
        min-height: 150px;
    }

    h1 {
        font-size: 20px;
    }

    h2 {
        font-size: 18px;
    }

    h3 {
        font-size: 14px;
    }

    h4 {
        font-size: 13px;
    }

    h5 {
        font-size: 12px;
    }
}


@media only screen and (min-width: 1060px) {

    .program-image {
        img {
            width: 75%;
            height: 100%;
        }
    }

    .accomodation-image {
        img {
            width: 75%;
            height: 100%;
        }
    }
}

@media only screen and (max-width: 1300px) {

    .program-image {
        img {
            width: 75%;
            height: 100%;
        }
    }

    .accomodation-image {
        img {
            width: 75%;
            height: 100%;
        }
    }
}

@media only screen and (max-width: 1600px) {

    .program-image {
        img {
            width: 50%;
            height: 75%;
        }
    }

    .accomodation-image {
        img {
            width: 50%;
            height: 75%;
        }
    }
}

.wrapper-document-mou {
    .main {
        position: relative;
        padding-top: 0;
        padding-bottom: 0;
    }

    .letter-bg {
        display: none;
    }

    p {
        font-size: 14px;
        color: #000;
    }

    table td {
        font-size: 14px;
        color: #000;
    }

    li {
        font-size: 14px;
        color: #000;
    }

    ul.alphabet {
        margin-left: 18px;
    }

    @media only screen and (max-width: 991px) {
        .main {
            position: relative;
            padding-left: 1cm;
            padding-right: 1cm;
        }
    }

}

.box-notification {
    background: #f0b10e4a;
    border-radius: 100%;
    width: 140px;
    height: 140px;
    line-height: 140px;
}
.select2,.select2-selection .select2-selection--single {
    outline:none !important;
}
.select2-container--default .select2-selection--single {
    background-color: unset !important;
    border: unset !important;
    border-radius: unset !important;
    outline:none !important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: unset !important;
    color: unset !important;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    padding: 0 !important;
}
.select2-container--default {
    font-family: 'gotam-narrow-light' !important;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    outline:none !important;
}
.select2-container.select2-container-dropdown.select2-container--open {
    margin-top: 6px !important;
}

// UPDATE LAYOUT
h1,h2,h3,h4,h5 {
    color: $heading-color;
    font-family: 'mulish-bold';
}
.font-ji {
    font-family: 'martel-bold' !important;
}
.title-section {
    font-family: 'martel-bold';
    font-size: 36px;
    margin-bottom: 20px;
}
.title-slider {
    font-family: 'martel-bold';
    font-size: 60px;
    line-height: 72px;
}
p {
    font-family: 'mulish';
    font-size: 18px;
}
.jejakimani-section-no-margin {
    padding: 96px 0;
}
.bg-whitespace {
    background-color: #F9FAFB;
}
.bg-greyspace {
    background-color: #F2F4F7;
}
.quote {
    font-family: 'martel';
    color: #101828;
    line-height: 28px;
}
.quote.small {
    font-family: 'martel';
    font-size: 16px;
    color: #475467
}
.quote.bold {
    font-family: 'martel-bold';
}
.accordion-jejakimani {
    .accordion-button {
        padding: 0;
        padding-right: 24px;
        background: none;
    }
    .accordion-body {
        margin-top: 12px;
        padding: 0;
        background: none;
    }
    .accordion-button p {
        font-family: 'mulish-bold';
        margin: 0;
    }
    .accordion-button:not(.collapsed) {
        color: $heading-color;
        background: none;
        box-shadow: none;
    }
    .accordion-item {
        background: none;
        border: none;
        color: unset;
        margin-bottom: 36px;
    }
    .accordion-item.with-border {
        border-top: 1px solid #EAECF0;
        padding: 24px 0;
        margin: 0;
    }
    & .accordion-item:last-of-type {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }
    & .accordion-button {
        &:focus {
            @include box-shadow(none);
        }
        &::after {
            position: absolute;
            content: "\f067";
            right: 0;
            top: 50%;
            @include transform(translateY(-50%));
            font-family: $fontawesome;
            font-size: 13px;
            
            margin-left: 0;
            background-image: none;
            border: 2px solid #D1AD4F;
            color: #D1AD4F;
            border-radius: 64px;
            height: 24px;
            width: 24px;
            line-height: 20px;
            text-align: center;
        }
    }
    & .accordion-button:not(.collapsed) {
        background: none;
        box-shadow: none;
        &::after {
            content: "\f068";
        }
    }
    & .accordion-collapse {
        border: none;
    }
    .accordion-button:not(.collapsed) h3 {
        color: #f0b10e;
    }
    .accordion-button h3:hover {
        color: #f0b10e;
    }
}
.btn-ji {
    position: relative;
    z-index: 9;
    background-color: #D1AD4F;
    color: #361F12 !important;
    padding: 16px 28px !important;
    border-radius: 100px;
    font-family: 'mulish-bold' !important;

    &.ji-f {
        display: flex;
        align-items: center;
    }
}
.btn-ji:hover {
    background-color: #835625;
    color: #fff !important;
}
.btn-ji:active {
    background-color: #BE9234 !important;
    color: #fff !important;
    border-color: #BE9234 !important;
    box-shadow: 0px 0px 0px 2px #D1AD4F66;
}
.btn-ji:disabled,
button.btn-ji[disabled]{
  background-color: #D0D5DD;
  color: #98A2B3 !important;
}
.btn-ji-outline {
    position: relative;
    z-index: 9;
    border-color: #FFF;
    color: #FFF !important;
    padding: 16px 28px !important;
    border-radius: 100px;
}
.btn-ji-outline:hover {
    background: #D1AD4F66;
    border-color: #FFF!important;
    color: #fff !important;
}
.btn-ji-outline:active {
    background-color: transparent !important;
    border-color: #FCFCFD66 !important;
    color: #fff !important;
    box-shadow: 0px 0px 0px 2px #FCFCFD66;
}
.btn-ji-outline:disabled,
button.btn-ji-outline[disabled]{
  border-color: #98A2B3;
  color: #98A2B3 !important;
}
.btn-ji.sm {
    font-size: 14px;
    padding: 8px 14px !important;
    border-radius: 64px;
}
.btn-link-ji {
    color: #BE9234 !important;
}
.btn-link-ji.sm {
    font-size: 14px;
}
.btn-ji-naked {
    background: transparent !important;
    border-color: transparent !important;
    color: #BE9234;
    outline: 0 !important;
    display: flex;
    align-items: center;
    gap: 0 8px;
    padding: 16px 28px !important;
    border-radius: 100px;

    &:hover {
        color: #6F4625 !important;
    }
}
.arrow-dr {
    transform: rotate(-45deg);
    padding-right: 8px;
}
.jejakimani-editor-form {
    label {
        font-size: 14px;
        color: #344054;
        font-family: 'mulish-bold';
    }
    .form-control:focus {
        outline: none;
        box-shadow: none;
    }
    .form-control {
        display: block;
        width: 100%;
        border: 1px solid #D0D5DD;
        padding: 8px 16px !important;
        border-radius: 8px;
        font-family: 'mulish';
    }
    img {
        max-height: 240px;
        object-fit: contain;
    }
    .btn-file {
        position: absolute;
        opacity: 0;
        right: 0;
        top: 0;
    }
    .btn-delete-file {
        position: absolute;
        top: 12px;
        right: 12px;
    }
}
.rounded-ji {
    border-radius: 24px !important;
}
@media only screen and (max-width: 768px) {
    .jejakimani-section-no-margin {
        padding: 2rem 0;
    }
    .title-section {
        font-family: 'martel-bold';
        font-size: 30px !important;
    }
    .subtitle-section {
        font-size: 18px !important;
    }
    .title-slider {
        font-family: 'martel-bold';
        font-size: 36px;
        line-height: 44px;
    }
    .accordion-jejakimani .accordion-button::after {
        font-size: 11px;
        height: 20px;
        width: 20px;
        line-height: 16px;
    }
    .btn-ji {
        padding: 12px 24px !important;
    }
    .btn-ji-outline {
        padding: 12px 24px !important;
    }
    .quote {
        font-size: 24px !important;
        line-height: normal;
    }
    .quote.small {
        font-size: 12px;
    }
}